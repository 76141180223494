export default [
  {
    name: "project/title",
    required: true,
    label: "Project Title",
    align: "left",
    sortable: true,
    field: (i: any) => (i.project ? i.project.title : "")
  },
  {
    name: "actionActionType/Action/name",
    required: true,
    label: "Service",
    align: "left",
    sortable: true,
    field: (i: any) =>
      `${i.actionActionType.action.name} - ${i.actionActionType.actionType.name}`
  },
  {
    name: "status",
    required: true,
    label: "Status",
    align: "left",
    sortable: false,
    field: (i: any) => "Completed"
  }
];
