












































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { ActionType } from "@/data/models/ActionTypes";
import SubArrayForm from "../SubArrayForm.vue";
import EntitySelect from "../EntitySelect.vue";
import FileInput from "../FileInput.vue";
import DynamicForm from "../DynamicForm.vue";
import { Guid } from "guid-typescript";
@Component({
  components: {
    FormContainer,
    Loader,
    SubArrayForm,
    EntitySelect,
    DynamicForm,
    FileInput,
  },
})
export default class FollowUpSubmissionForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public requestId!: string;

  @Prop()
  public actionId!: string;

  public provider!: DataProvider<ActionType>;

  public crud!: ICrudClient<ActionType>;

  private item: any = null;

  private questionnaire: any = null;
  created() {
    this.item = {
      followUpId: null,
      followUp: null,
      requestId: this.requestId,
      uploadedFile: null,
      uploadedFileId: null,
      submission: null,
    };
    this.provider = this.$service.providers.followUpSubmissions;
    this.crud = this.$service.crud.followUpSubmissions;
  }

  @Watch("item", { immediate: true, deep: true })
  onItemChanged() {
    if (this.item && this.item.followUp && this.item.followUp.questionnaire) {
      this.questionnaire = this.item.followUp.questionnaire;
      if (!this.item.submission) {
        this.item.submission = {
          answers: [],
        };
      }
      this.item.submission.questionnaireId = this.item.followUp.questionnaireId;
    } else if (this.item) {
      this.item.submission = null;
    }
  }
}
