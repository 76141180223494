




































































































































































import DataProvider from "@/lib/DataProvider";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HouseholdMembersActions from "@/data/columns/RequestSubmissionColumns";
import NonSessionActionColumns from "@/data/columns/NonSessionActionColumns";
import ModelTable from "@/components/ModelTable.vue";
import AddHouseholdMemberActionForm from "@/components/forms/AddHouseholdMemberActionForm.vue";
import HouseholdMemberActionSessionsTable from "@/components/HouseholdMemberActionSessionsTable.vue";
import AddHouseholdMemberSessionRequestForm from "@/components/forms/AddHouseholdMemberSessionRequestForm.vue";
import FollowUpSubmissionForm from "@/components/forms/FollowUpSubmissionForm.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import Loader from "@/components/Loader.vue";
import QuestionnaireSubmit from "@/components/forms/QuestionnaireSubmit.vue";
import { saveAs } from "file-saver";
@Component({
  components: {
    ModelTable,
    AddHouseholdMemberActionForm,
    HouseholdMemberActionSessionsTable,
    AddHouseholdMemberSessionRequestForm,
    DynamicForm,
    QuestionnaireSubmit,
    FollowUpSubmissionForm,
    Loader
  }
})
export default class HouseholdMemberStatus extends Vue {
  @Prop()
  public id!: string;

  private selectedSession: any = null;
  private actionsProvider: DataProvider<any> | null = null;
  private nonSessionActionsProvider: DataProvider<any> | null = null;
  private actionsColumns = HouseholdMembersActions;
  private nonSessionActionColumns = NonSessionActionColumns;
  private addActionDialog = false;
  private addRequestDialog = false;
  private followUpDialog = false;
  private selectedAction = null;
  private selectedItem = null;
  private submission: any = null;
  private answers: any = null;
  private showResults = false;
  private followUpId = null;
  requestId = null;
  actionId = null;
  item: any = null;
  async initialize() {
    this.actionsProvider = this.$service.providers.householdMembersRequests(
      this.id
    );

    this.nonSessionActionsProvider = this.$service.providers.householdMembersActions(
      this.id,
      true
    );

    this.item = await this.$service.providers.householdMembers.fetchItemAsync(
      this.id
    );
  }

  async loadSubmission(id: string) {
    this.submission = { id };

    this.showResults = true;
  }
  async save() {
    await this.$service.crud.householdMembers.saveAsync(this.item);
  }

  async download() {
    const utc = new Date().toJSON().slice(0, 10);
    saveAs(
      await this.$service.downloadHouseholdMemberActions(this.id),
      `Follow_Up_${this.item.firstName}_${this.item.lastName}_${utc}.xlsx`
    );
  }

  async extract() {
    const utc = new Date().toJSON().slice(0, 10);
    saveAs(
      await this.$service.extract("FollowUpSubmissions/Extract", null, this.id),
      `${this.item.firstName}_${this.item.lastName}_Follow_Up_${utc}.xlsx`
    );
  }
}
