var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('loader',{attrs:{"retry":_vm.initialize}},[(_vm.item)?_c('q-input',{staticClass:"text-subtitle2 q-pa-sm",attrs:{"borderless":"","readonly":"","filled":"","label":"Case Statuses for","value":((_vm.item.firstName) + " " + (_vm.item.lastName) + " (" + (_vm.item.dateOfBirth.split('T')[0]) + ")")}}):_vm._e(),(_vm.item)?_c('div',{staticClass:"row items-center q-col-gutter-sm q-pa-sm"},[_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"label":"Case Status","options":['Open', 'Closed'],"filled":"","dense":""},model:{value:(_vm.item.status),callback:function ($$v) {_vm.$set(_vm.item, "status", $$v)},expression:"item.status"}})],1),_c('div',{staticClass:"col-6"},[_c('q-btn',{attrs:{"label":"Save","color":"primary","loading":_vm.$proxy.saving},on:{"click":function($event){_vm.$runAsync('saving', function () { return _vm.save(); })}}}),_c('q-btn',{staticClass:"q-ml-md",attrs:{"icon":"download","label":"Download","flat":"","no-caps":"","loading":_vm.$proxy['downloading' + _vm.id]},on:{"click":function($event){_vm.$runAsync('downloading' + _vm.id, function () { return _vm.download(); })}}})],1)]):_vm._e(),_c('q-separator'),_c('model-table',_vm._b({attrs:{"provider":_vm.actionsProvider,"columns":_vm.actionsColumns,"crud":_vm.$service.crud.sessionSubmissions,"getName":function (i) { return ("" + (i.name)); },"viewPermission":function (r) { return true; }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('q-card',{staticClass:"q-pa-sm q-mx-sm",attrs:{"flat":""}},[_c('div',{staticClass:"title"},[_vm._v("Requests & Follow Up")])]),_c('q-btn',{attrs:{"icon":"download","label":"Extract","flat":"","no-caps":""},on:{"click":function($event){_vm.$runAsync('downloading', function () { return _vm.extract(); })}}})]},proxy:true},{key:"row-actions",fn:function(ref){
var scope2 = ref.scope;
return [_c('q-btn',{attrs:{"icon":"edit","flat":"","no-caps":""},on:{"click":function($event){_vm.addRequestDialog = true;
          _vm.selectedAction = scope2.row.session;
          _vm.selectedItem = scope2.row;}}},[_c('q-tooltip',[_vm._v(" Edit Request ")])],1),_c('q-btn',{attrs:{"icon":"next_plan","color":scope2.row.followUp ? 'primary' : 'black',"flat":"","no-caps":""},on:{"click":function($event){_vm.actionId = scope2.row.request.actionId;
          _vm.requestId = scope2.row.id;
          _vm.followUpId = scope2.row.followUp ? scope2.row.followUp.id : null;
          _vm.followUpDialog = true;}}},[_c('q-tooltip',[_vm._v(" Follow Up ")])],1)]}}])},'model-table',Object.assign({}, _vm.$props, _vm.$attrs),false)),_c('q-separator'),_c('model-table',_vm._b({attrs:{"provider":_vm.nonSessionActionsProvider,"columns":_vm.nonSessionActionColumns,"crud":_vm.$service.crud.householdMembersActions,"getName":function (i) { return ("" + (i.name)); },"viewPermission":function (r) { return true; },"title":'Non session actions'},scopedSlots:_vm._u([{key:"row-actions",fn:function(ref){
          var scope = ref.scope;
return [_c('q-btn',{attrs:{"icon":"edit","flat":"","no-caps":""},on:{"click":function($event){_vm.selectedAction = scope.row;
          _vm.addActionDialog = true;}}},[_c('q-tooltip',[_vm._v(" Edit Submission ")])],1)]}}])},'model-table',Object.assign({}, _vm.$props, _vm.$attrs),false)),_c('q-dialog',{attrs:{"persistent":"","scrollable":""},model:{value:(_vm.addRequestDialog),callback:function ($$v) {_vm.addRequestDialog=$$v},expression:"addRequestDialog"}},[_c('add-household-member-session-request-form',{attrs:{"inDialog":true,"session":_vm.selectedAction ? _vm.selectedAction : null,"id":_vm.selectedItem ? _vm.selectedItem.id : null},on:{"edit":function($event){_vm.addRequestDialog = true;
        _vm.selectedAction = _vm.scope.row;},"save":function($event){_vm.addRequestDialog = false;
        _vm.initialize();}}})],1),_c('q-dialog',{attrs:{"persistent":"","scrollable":""},model:{value:(_vm.followUpDialog),callback:function ($$v) {_vm.followUpDialog=$$v},expression:"followUpDialog"}},[_c('follow-up-submission-form',{attrs:{"id":_vm.followUpId,"requestId":_vm.requestId,"actionId":_vm.actionId,"inDialog":true},on:{"save":function($event){_vm.followUpDialog = false;
        _vm.initialize();}}})],1),_c('q-dialog',{attrs:{"scrollable":"","persistent":""},model:{value:(_vm.showResults),callback:function ($$v) {_vm.showResults=$$v},expression:"showResults"}},[(_vm.submission)?_c('questionnaire-submit',{attrs:{"questionnaireId":_vm.submission.id,"inDialog":true},on:{"save":function($event){_vm.showResults = false}}}):_vm._e()],1),_c('q-dialog',{attrs:{"persistent":""},model:{value:(_vm.addActionDialog),callback:function ($$v) {_vm.addActionDialog=$$v},expression:"addActionDialog"}},[_c('add-household-member-action-form',{attrs:{"inDialog":true,"editMode":true,"memberId":_vm.id,"action":_vm.selectedAction},on:{"save":function($event){_vm.addActionDialog = false}}})],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }